import { BodyText, HeadingText } from 'components/common/Typography';
import { Asset } from 'components/contentful/Asset';
import { Container } from 'components/layout/Container';
import { makeStyles } from 'lib/makeStyles';
import { UIComponentProps } from 'types/contentful';

import { FeatureBullets } from '../../contentful/FeatureBullet/FeatureBullets';
import { ReviewsStatistic } from './ReviewsStatistic';

type Props = UIComponentProps;

const useStyles = makeStyles({
  outerWrapper: ['bg-white'],
  containerInner: ['mx-auto px-1 md:px-4 pt-16 md:pt-24'],
  contentGrid: ['grid grid-cols-1 space-y-6 md:grid-cols-2 md:space-x-6 md:space-y-0'],
  contentWrapper: ['flex flex-col justify-center items-center md:justify-center'],
  subHeading: ['mt-2'],
  listWrapper: ['list-none mt-12'],
  imageWrapper: ['flex justify-center justify-items-center items-center md:justify-end'],
  image: ['md:max-w-sm px-6 mt-4 mb-2 md:my-0'],
  reviewStatisticsWrapper: ['max-w-6xl mx-auto md:px-4 md:pb-24'],
  statisticsGrid: ['grid grid-cols-2 my-12 md:grid-cols-5', 'md:space-x-6 md:space-y-0 md:mt-32'],
  listItem: 'list-none'
});
export const HomepageSectionReviews = ({ entry, items }: Props) => {
  const [heading, subheading] = entry.richTextSections;
  const featureBullets = items.get('FeatureBullet');
  const statistics = items.get('Statistic');
  const [iphoneImage] = items.get('Image');
  const styles = useStyles();

  return (
    <div className={styles.outerWrapper}>
      <Container>
        <div className={styles.containerInner}>
          <div className={styles.contentGrid}>
            <div className={styles.contentWrapper}>
              <HeadingText size="SM" as="h2">
                {heading.primaryText}
              </HeadingText>
              <BodyText as="p" className={styles.subHeading}>
                {subheading.primaryText}
              </BodyText>
              <FeatureBullets
                as="h3"
                className={styles.listWrapper}
                featureBullets={featureBullets}
              />
            </div>
            <div className={styles.imageWrapper}>
              <Asset rawImgTag entry={iphoneImage} className={styles.image} />
            </div>
          </div>
        </div>
      </Container>
      <div className={styles.reviewStatisticsWrapper}>
        <div className={styles.statisticsGrid}>
          {statistics.map((statistic) => (
            <ReviewsStatistic entry={statistic} key={statistic.id} />
          ))}
        </div>
      </div>
    </div>
  );
};
