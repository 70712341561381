import { Glyph, GlyphProps } from 'components/common/Glyph';
import React from 'react';

const InfoIconDetails = React.memo((props: GlyphProps) => (
  <Glyph width={14} height={14} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 3C0 1.34315 1.34315 0 3 0H11C12.6569 0 14 1.34315 14 3V11C14 12.6569 12.6569 14 11 14H3C1.34315 14 0 12.6569 0 11V3ZM7 7C7.55228 7 8 7.44772 8 8V10.5C8 11.0523 7.55228 11.5 7 11.5C6.44772 11.5 6 11.0523 6 10.5V8C6 7.44772 6.44772 7 7 7ZM7 5C7.55228 5 8 4.55228 8 4C8 3.44772 7.55228 3 7 3C6.44772 3 6 3.44772 6 4C6 4.55228 6.44772 5 7 5Z"
      fill="currentColor"
    />
  </Glyph>
));

InfoIconDetails.displayName = 'InfoIconDetails';

export { InfoIconDetails };
