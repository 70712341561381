import { InfoTooltip } from 'components/common/TooltipNotifications';
import { BodyText } from 'components/common/Typography';
import { Markdown } from 'components/Markdown';
import { InfoIconDetails } from 'icons/places/InfoIconDetails';
import { makeStyles } from 'lib/makeStyles';
import { SerializedComponentItem } from 'types/contentful/SerializedComponentItem';

type Props = {
  entry: SerializedComponentItem;
};

const useStyles = makeStyles({
  root: 'flex flex-col justify-start items-center border-b border-r border-neutralBorder py-6 md:py-0 md:border-0',
  labelWrapper: 'flex',
  title: 'text-center mb-2',
  description: 'text-black text-3xl font-semibold',
  tooltip: 'cursor-pointer text-neutralIconLoud',
  tooltipIcon: 'ml-1 mt-1'
});

export const ReviewsStatistic: React.FC<Props> = ({ entry }) => {
  const tooltip = entry.items?.find((item) => item.type === 'Tooltip');
  const styles = useStyles();

  return (
    <div className={styles.root}>
      <div className={styles.labelWrapper}>
        <BodyText as="h4" className={styles.title}>
          {entry.primaryText}
        </BodyText>
        {tooltip && (
          <InfoTooltip message={tooltip.primaryText} className={styles.tooltip}>
            <InfoIconDetails className={styles.tooltipIcon} />
          </InfoTooltip>
        )}
      </div>
      {/* Markdown has P tag already, this should be a div to avoid react hydration error */}
      <div className={styles.description}>
        <Markdown markdown={entry.secondaryText} />
      </div>
    </div>
  );
};
